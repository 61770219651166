<template>
    <v-layout>
        <v-app-bar
        absolute
        color="#fff"
    >
    
        <img src="@/assets/logo-pharmacall.jpg" height="56" />
        <v-spacer></v-spacer>    
    </v-app-bar>  
    <div class="register">
        <v-card
            elevation="2"
            class="register__card"
            >
            <h1 class="register__card-title">
                Confirmar registro
            </h1>
            <p v-if="!done" style="padding:0 25px;text-align:justify;">
                Para poder confirmar tu cuenta y acceder al sistema debes introducir una contraseña. <br/> La contraseña debe tener como mínimo 8 caracteres y debe incluir al menos una mayúscula, una minúscula y un número
            </p>
            <p v-if="done" style="padding:0 25px;text-align:justify;">
                Su contraseña ha sido establecida, ya puedes inciar sesión.
            </p>
            <validation-observer
                ref="observer"
                v-slot="{ invalid }"
            >
                <form class="register__card__form" @submit.prevent="register">
                    <v-container>
                        <validation-provider
                            v-slot="{ errors }"
                            name="Contraseña"
                            :rules="{
                                required: true,
                                regex: '^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$'
                            }"
                        >
                            <v-text-field
                                    label="Contraseña"
                                    outlined
                                    v-model="password"
                                    :error-messages="errors"
                                    type="password"
                                    dense
                                    v-if="!done"
                                    required
                                ></v-text-field>
                        </validation-provider>
                        <validation-provider
                            v-slot="{ errors }"
                            name="Repetir contraseña"
                            :rules="{
                                required: true,
                                samePass: password
                            }"
                        >
                            <v-text-field
                                label="Repetir contraseña"
                                outlined
                                v-model="repeatPassword"
                                :error-messages="errors"
                                type="password"
                                dense
                                v-if="!done"
                                required
                            ></v-text-field>
                        </validation-provider>
                        <p v-if="error" style="padding:0 25px;font-size:14px;text-align:center;color:red;font-weight:bold;">
                            Error al establecer la contraseña, el token fue utilizado
                        </p>                            
                        <div class="register__card__form-button" style="tex-align:center;marign: 0 auto;">                            
                            <v-btn
                                depressed
                                v-if="!done"
                                color="primary"
                                type="submit"
                                :loading="loading"
                                :disabled="loading || invalid || !token"
                                >
                                Confirmar cuenta
                            </v-btn>
                            <v-btn
                                v-if="done"
                                depressed
                                color="primary"
                                to="/login"
                                >
                                Iniciar sesión
                            </v-btn>                          
                        </div>
                        <p class="register__card__form-login">¿Ya tienes una cuenta activada? <router-link to="/login">Inicia sesión</router-link></p>
                    </v-container>
                </form>
            </validation-observer>
        </v-card>
    </div>
        <div class="home__copyright">
            <div class="home__copyright__container">
                <span class="copyright">© Copyright - Pharma Call<div style="float:right;"> 
                    <router-link to="/condiciones-de-la-promocion" style="color:white;text-decoration:none;">Condiciones de la promoción</router-link> &nbsp;|&nbsp;
                    <a style="color:white;text-decoration:none;" target="new" href="https://www.pharma-call.com/politica-privacidad/">Política de privacidad</a> &nbsp;|&nbsp; <a target="new" style="color:white;text-decoration:none;" href="https://www.pharma-call.com/politica-de-cookies/">Política de cookies</a> </div></span>
            </div>
        </div>    
</v-layout>    
</template>  

<script>
import { required, regex } from 'vee-validate/dist/rules'
import { ValidationProvider, ValidationObserver, setInteractionMode, extend } from 'vee-validate';
import httpService from '@/services/httpService'

setInteractionMode('eager');

extend('required', {
    ...required,
    message: 'El campo es requerido',
  });

extend('regex', {
    ...regex,
    message: 'La contraseña debe tener como mínimo 8 caracteres y debe incluir al menos una mayúscula, una minúscula y un número',
});

const samePass = (value, password) => {
  return value == String(password);
};

extend('samePass', samePass, 'password');

extend('samePass', {
    message: 'Las contraseñas deben coincidir'
});

export default {
  name: 'Register',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data () {
      return {
        loading: false,
        error: false,
        done: false,
        token:  this.$route.query.token,
        email: null,
        password: null,
        repeatPassword: null
      }
  },
  mount() {
    console.log('this shit')
    this.token = this.$route.query.token
    console.log(this.token)
  },  
  methods: {
      iniciarSesion() {
          console.log('login')
        this.$router.go('/login');
      },
        register() {
            this.loading = true;
            this.$refs.observer.validate().then((valid) => {
                if (valid) {
                    httpService.post('/auth/set-password?token=' + this.token, {
                        password: this.password
                    }).then((data) => {
                        if (data && data.result && data.result != 'error') {
                            this.error = true;                            
                            this.loading = false;
                        } else {
                            this.success = true;                            
                            this.done = true;
                            this.loading = false;
                        }
                    }).catch(() => {
                        this.errorMsg = 'Se ha producido un error al actualizar la contraseña. Inténtalo de nuevo en unos minutos.';
                        this.loading = false;
                    })
                } else {
                    this.loading = false;
                }                
                // this.loading = false;
            })
        }
  }
}
</script>

<style lang="scss" scoped>
    @import '@/variables';
    @import '@/variables';
.login {
        height: calc(100vh - #{$header_size_mobile} - 100px);
        margin-top: calc(#{$header_size_mobile} + 20px);
        @media (min-width: 960px) { 
            height: calc(100vh - #{$header_size_desktop} - 250px);
            margin-top: calc(#{$header_size_desktop} + 35px);
        }
        &__card {
            margin: 0 auto;
            width: 95%;
            max-width: 500px;
            color: $text_color !important;
            @media (min-width: 960px) { 
                margin: 0 auto;
            }
            &-title {
                color: $text_color;
                font-weight: 400 !important;
                font-size: 1.8em;
                text-align: center;
                padding: 15px 0 10px 0;
            }
            &__form {
                margin: 0 20px;
                &-button {
                    display: flex;
                    button {
                        margin: auto;
                    }
                }
                &-login {
                    text-align: center;
                    margin: 20px 0;
                }
                &-error {
                    color: #f44336;
                    text-align: center;
                    font-size: 15px;
                }
            }
        }
    }

        .home {
        margin-top: $header_auth_size_mobile;
        color: $text_color;
        background-color:white;
        width: 100%;
        font-weight: 400;
        margin-left: auto;
        margin-right: auto;
        @media (min-width: 960px) { 
            margin-top: $header_auth_size_desktop;
        }
        &__cupones {
            margin-bottom:40px;
        }
        &__password {
            max-width:1100px;
        }
        &__footer {
            margin-top:20px;
            background-color:#109bc5;
            min-height: 300px;
        }
        &__copyright {
            bottom: 0;
            position: absolute;
            left: 0;
            width: 100%;            
            background-color:#11abd6;
            min-height: 50px;
            &__container {
                color:white;
                margin: 0 auto;
                max-width:1100px;
                padding-top:15px;
                font-size:85%;
            }
        }
        &__banner {
            background-color: $primary-color;
            min-height: 230px;
            padding: 20px;
            color: white;
            text-align: center;
            font-size: 22px;
            display: flex;
            line-height: 28px;
            @media (min-width: 960px) { 
                font-size: 26px;
                line-height: 32px;
            }
            &__text {
                margin: auto auto 30px auto;
                font-weight: bold;
                &-client {
                    color: black;
                }
                &-subtext {
                    font-weight: 400;
                }
            }
        }
        &__points, &__exchange {
            background-color: white;
            border-radius: 10px;
            margin: 20px auto;
            padding: 20px;
            text-align: center;
            color: black;
            max-width: 400px;
            @media (min-width: 600px) {
                margin: 60px 20px 60px 20px;
                padding: 30px;
            }
        }
        &__coupon {            
            border-radius: 10px;
            margin: 5px auto;
            padding: 5px;
            margin-bottom: 40px;
            text-align: center;
            color: black;
            max-width: 600px;
            @media (min-width: 600px) {                
                padding: 30px;
            }
            &-icon {
                color: black;                
                border-radius: 50%;
                font-size: 40px;
                padding: 3px;
            }
            &-text {
                margin-top: 10px;
                font-weight: bold;
                font-size: 13px;
                margin-bottom: 0;
            }
            &-coupon {
                font-size: 30px;
                color: $primary-color;
                padding:5px;
                font-weight: bold;
                margin-bottom: 0;
            }
            &-subtext {
                color: black;
                margin-bottom: 0;
                font-size: 12px;
            }               
        }
        &__points {
            &-icon {
                color: white;
                background-color: black;
                border-radius: 50%;
                font-size: 15px;
                padding: 3px;
            }
            &-text {
                margin-top: 10px;
                margin-bottom: 0;
            }
            &-points {
                font-size: 30px;
                color: $primary-color;
                font-weight: bold;
                margin-bottom: 0;
            }
            &-subtext {
                color: $primary-color;
                margin-bottom: 0;
            }
        }
        &__exchange {
            &-title {
                font-weight: bold;
                margin-bottom: 6px;
            }
            &-subtitle {
                color: $text_color;
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 20px;
            }
            &-button {
                text-transform: none;
                padding: 20px 15px !important;
            }
        }
        &__personal {
            background-color: white;
            padding: 60px 20px;
            &-title {
                text-align: center;
                font-weight: 400;
                color: $primary-color;
                margin-bottom: 30px;
            }
            &__cards {
                @media (min-width: 600px) {
                    display: flex;
                }
                &__card {
                    border: 2px solid $primary-color;
                    display: flex;
                    margin: 10px 20px;
                    padding: 15px;
                    cursor: pointer;
                    &:hover {
                        transform: scale(1.05);
                    }
                    &-icon {
                        color: $primary-color;
                        margin-right: 8px;
                    }
                    &-text {
                        color: $text-color;
                        font-size: 14px;
                        margin: auto;
                    }
                }
            }
        }
        &__info {
            background-color: white;
            max-width: 1100px;            
            margin: 0 auto;
            padding: 30px 10px;
            &-title {
                text-align: center;
                font-weight: 400;
                color: $primary-color;
                margin-bottom: 10px;
            }
            &-subtitle {
                color: $text-color;
                text-align: center;
                font-size: 14px;
                line-height: 18px;
                max-width: 700px;
                margin: auto;
                padding-bottom: 50px;
            }
            &__cards {
                display: flex;
                &__card {
                    position: relative;
                    max-width: 80%;
                    padding: 10;
                    line-height: 0;
                    img {
                        width: 100%;
                        height: auto;
                        overflow: hidden;
                        border-radius: 50%;
                    }
                    &-text {
                        position: absolute;
                        top: 50%;
                        width: 100%;
                        text-align: center;
                        color: white;
                    }
                    &-overlay {
                        opacity: 0.5;
                        background-color: #333333;
                        position: absolute;
                        border-radius: 50%;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                    }
                }
            }
        }
    }
    .register {
        height: calc(100vh - #{$header_size_mobile});
        margin: 0 auto;
        margin-top: $header_size_mobile;
        background-color: #f7f7f7;
        @media (min-width: 960px) { 
            height: calc(100vh - #{$header_size_desktop} - 250px);
            margin-top: $header_size_desktop;
        }
        &__card {
            margin: 20px auto;
            width: 95%;
            max-width: 500px;
            color: $text_color !important;
            @media (min-width: 960px) { 
                margin: 35px auto;
            }
            &-title {
                color: $text_color;
                font-weight: 400 !important;
                font-size: 1.8em;
                text-align: center;
                padding: 15px 0 10px 0;
            }
            &__form {
                margin: 0 20px;
                &-button {
                    display: flex;
                    button {
                        margin: auto;
                    }
                }
                &-login {
                    text-align: center;
                    margin: 20px 0;
                }
            }
        }
    }
</style>
