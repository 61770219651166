var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',[_c('v-app-bar',{attrs:{"absolute":"","color":"#fff"}},[_c('img',{attrs:{"src":require("@/assets/logo-pharmacall.jpg"),"height":"56"}}),_c('v-spacer')],1),_c('div',{staticClass:"register"},[_c('v-card',{staticClass:"register__card",attrs:{"elevation":"2"}},[_c('h1',{staticClass:"register__card-title"},[_vm._v(" Confirmar registro ")]),(!_vm.done)?_c('p',{staticStyle:{"padding":"0 25px","text-align":"justify"}},[_vm._v(" Para poder confirmar tu cuenta y acceder al sistema debes introducir una contraseña. "),_c('br'),_vm._v(" La contraseña debe tener como mínimo 8 caracteres y debe incluir al menos una mayúscula, una minúscula y un número ")]):_vm._e(),(_vm.done)?_c('p',{staticStyle:{"padding":"0 25px","text-align":"justify"}},[_vm._v(" Su contraseña ha sido establecida, ya puedes inciar sesión. ")]):_vm._e(),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"register__card__form",on:{"submit":function($event){$event.preventDefault();return _vm.register($event)}}},[_c('v-container',[_c('validation-provider',{attrs:{"name":"Contraseña","rules":{
                                required: true,
                                regex: '^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$'
                            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [(!_vm.done)?_c('v-text-field',{attrs:{"label":"Contraseña","outlined":"","error-messages":errors,"type":"password","dense":"","required":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}):_vm._e()]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Repetir contraseña","rules":{
                                required: true,
                                samePass: _vm.password
                            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [(!_vm.done)?_c('v-text-field',{attrs:{"label":"Repetir contraseña","outlined":"","error-messages":errors,"type":"password","dense":"","required":""},model:{value:(_vm.repeatPassword),callback:function ($$v) {_vm.repeatPassword=$$v},expression:"repeatPassword"}}):_vm._e()]}}],null,true)}),(_vm.error)?_c('p',{staticStyle:{"padding":"0 25px","font-size":"14px","text-align":"center","color":"red","font-weight":"bold"}},[_vm._v(" Error al establecer la contraseña, el token fue utilizado ")]):_vm._e(),_c('div',{staticClass:"register__card__form-button",staticStyle:{"tex-align":"center","marign":"0 auto"}},[(!_vm.done)?_c('v-btn',{attrs:{"depressed":"","color":"primary","type":"submit","loading":_vm.loading,"disabled":_vm.loading || invalid || !_vm.token}},[_vm._v(" Confirmar cuenta ")]):_vm._e(),(_vm.done)?_c('v-btn',{attrs:{"depressed":"","color":"primary","to":"/login"}},[_vm._v(" Iniciar sesión ")]):_vm._e()],1),_c('p',{staticClass:"register__card__form-login"},[_vm._v("¿Ya tienes una cuenta activada? "),_c('router-link',{attrs:{"to":"/login"}},[_vm._v("Inicia sesión")])],1)],1)],1)]}}])})],1)],1),_c('div',{staticClass:"home__copyright"},[_c('div',{staticClass:"home__copyright__container"},[_c('span',{staticClass:"copyright"},[_vm._v("© Copyright - Pharma Call"),_c('div',{staticStyle:{"float":"right"}},[_c('router-link',{staticStyle:{"color":"white","text-decoration":"none"},attrs:{"to":"/condiciones-de-la-promocion"}},[_vm._v("Condiciones de la promoción")]),_vm._v(" | "),_c('a',{staticStyle:{"color":"white","text-decoration":"none"},attrs:{"target":"new","href":"https://www.pharma-call.com/politica-privacidad/"}},[_vm._v("Política de privacidad")]),_vm._v(" | "),_c('a',{staticStyle:{"color":"white","text-decoration":"none"},attrs:{"target":"new","href":"https://www.pharma-call.com/politica-de-cookies/"}},[_vm._v("Política de cookies")])],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }